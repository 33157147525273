/* You can add global styles to this file, and also import other style files */
@import (reference) '~proceduralsystem-clientcomponents/styles/variables.less';
@import '~@angular/cdk/overlay-prebuilt.css';

html {
  margin: 0;
  height: 100%;
  overflow: hidden;

  body {
    margin: 0;
    height: 100%;
  }

}
.bold {
  font-weight: bold;
}

.silver {
  color: @secondary-40;
}

.report-ckEditor p, .ta_ob_description p, .ta_rr_rapporteurNotes p, .ta_rr_rapporteurAnnouncement p {
  text-align: center;
}

.rapporteur-announcement p {
  text-align: left;
}

.report-noteCkEditor .cke_contents {
  min-height: 60px;
}

.disable-picker {
  opacity: .5;
  color: gray;
  pointer-events: none;
}
.app-container {

  > .body {
    height: 100%;

    &.has-actions {
      height: calc(100% - 80px);
    }
  }
}

.arrangement {
  margin: 20px;
}

.disable-fields {
  input, div {
    pointer-events: none!important;
    background-color: #bfbbaa!important;
    border: 1px solid #bfbbaa!important;
  }
}

.disabled-day {
  input, textarea {
    pointer-events: none!important;
    background-color: #bfbbaa!important;
    border: 1px solid #bfbbaa!important;
  }

  ::ng-deep oir-toggle {
    pointer-events: none!important;
  }

  ::ng-deep oir-multiselect {
    input, div {
      pointer-events: none!important;
      background-color: #bfbbaa!important;
      border: 1px solid #bfbbaa!important;
    }
  }

  ::ng-deep oir-custom-multiselect {
    input, div {
      pointer-events: none!important;
      background-color: #bfbbaa!important;
      border: 1px solid #bfbbaa!important;
    }
  }

  ::ng-deep oir-typeahead {
    input, div {
      pointer-events: none!important;
      background-color: #bfbbaa!important;
      border: 1px solid #bfbbaa!important;
    }
  }
  ::ng-deep oir-custom-typeahead{
    input, div {
      pointer-events: none!important;
      background-color: #bfbbaa!important;
      border: 1px solid #bfbbaa!important;
    }
  }
}

.arrangements-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.padding-5 {
  padding: 5px 0;
}

.padding-20 {
  padding: 20px 0;
}

.margin-20 {
  margin: 20px 0;
}

.day-arrangement .accordion,
.add-new-section .accordion {
  width: 98.5%;
  margin-left: 20px;
  padding-right: 27px;
}


// form-tabs.component.less
.tab-container {

  p-tabview {
    .p-tabview {
      ul.p-tabview-nav{
        list-style: none;
        margin-left: 20px;
        margin-bottom: 0px;

        li {
          display: inline-block;
          height: auto;
          width: auto;
          padding: 0;
          color: #444;
          border: none;
          background: repeating-linear-gradient( -40deg, @neutral-background-base, @neutral-background-base 5px, @neutral-background-dark 5px, @neutral-background-dark 7.5px );
          font-size: 15px;
          font-family: 'Montserrat-Regular', san-serif;
          font-weight: bold;
          vertical-align: middle;
          text-align: center;
          outline: none;

          &:hover {
            cursor: pointer;
          }

          &.p-highlight {
            background: white;
            color: @primary-base;
            border: 1px solid @primary-50;
            border-bottom: none;

            &:hover {
              cursor: pointer;
            }

            a {
              text-decoration: none;
              color: @primary-base;
            }
          }
          &.p-tabview-ink-bar {
            display: none;
          }
        }

        a, a:hover, a:visited, a:focus {
          text-decoration: none;
          color: #444;
          padding: 20px 30px 15px 30px;
          outline: none;
        }
      }

      .p-tabview-panel.p-helper-hidden {
        display:none;
      }
    }


  }

}

.p-tabview-panel .content .tab-container-finalise {
  p-tabview {
    .p-tabview {
      margin-left:25px;
      .p-tabview-nav {
        padding-left: 0px;
        margin-left: -20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #d4cfbe;

        li {
          display: inline-block;
          list-style: none;
          height: auto;
          width: auto;
          padding: 20px 30px 15px 30px;
          background: white;
          // background: none;
          color: #444;
          border: none;
          font-size: 15px;
          font-family: 'Montserrat-Regular', sans-serif;
          font-weight: bold;
          vertical-align: middle;
          text-align: center;

          &.p-highlight {
            box-shadow: inset 0px -5px 0px 0px @primary-90;
          }

          :hover {
            text-decoration: none;
          }

          a {
            text-decoration: none !important;
            color: #444 !important;

            &:focus {
              outline: none;
            }
          }
        }
      }
      .p-tabview-panel.p-helper-hidden {
        display:none;
      }
    }
    ::ng-deep oir-pagination {
      ul {
        border-bottom: 0!important;
      }
    }
  }
}


// form-inputs.component.less
.app-container > .body .content {
  margin-bottom: 25px;

  .content {
    padding: 0;
    margin-bottom: 0;
  }
  .row.form-item {
    margin-bottom: 20px;

    .item-instance {
      textarea#full-width {
        width: 100%;
      }

      ::ng-deep oir-datepicker {
        .datepicker {
          margin-top: 5px;
        }
      }
    }

    &:last-of-type {
      margin-bottom: auto;
    }

    .item-code {
      padding: 5px;
      padding-left: 20px;

      textarea {
        height: 100%;
        width: 100%;
        padding: 10px;
        border: 1.5px dashed @primary-50;
        border-radius: 2.5px;
        resize: none;

        &#text-input {
          height: 123px;
        }

        &#textarea {
          height: 143px;
        }

        &:focus {
          outline: none !important;
          border: 1px solid @primary-90;
          box-shadow: 0 0 10px @primary-90;
        }

        &::selection {
          background-color: @primary-base;
          color: @white;
        }
      }
    }
  }
}

ul.radio-list {
  padding-left: 0;
  list-style: none;

  li {

    p,
    input[type="radio"] {
      display: inline-block;
    }

    p {
      padding-left: 5px;
    }
  }
}

p.inline {
  display: inline-block;
  padding-left: 5px;
}

.toggle-label {
  width: 100%;
  display: inline-block;
}

.toggle-container {
  display: inline-block;
  min-width: 130px;

  .toggle-text {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;

    &.left {
      margin-right: 10px;
    }

    &.right {
      margin-left: 10px;
    }
  }
}

mat-dialog-content, .mat-dialog-title {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 22px;
}

::ng-deep oir-singleselect {
  .select-container {
    margin-top: 5px;
  }
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-bottom: 20px;
}

.dropdown-toggle {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  justify-content: space-between !important;
  white-space: nowrap !important;
}

.flex {
  display: flex;
}

.flex-align {
  display: flex;
  align-items: flex-end;
  padding: 20px 0;
}

.custom-form-item {
  display: flex;
  margin-bottom: 10px;
}

.margin-t10-b18 {
  margin: 10px 0px 0px 18px;
}

.w100 {
  width: 100%;
}

.hide-button {
  opacity: 0;
  pointer-events: none;
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// Toast message cross position fix

.toast-container .notification-box .title-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .title {
    width: calc(100% - 50px);
  }
}
.time-wrapper {
         > .time-picker {
            &.ng-invalid {
                border: 1px solid @primary-50!important;
                background-color: white!important;
                background-image: url('../node_modules/proceduralsystem-clientcomponents/styles/img/unselected-clock.svg')!important;
                background-position: right 9px top 7px!important;
                background-repeat: no-repeat!important;
        }
    }
}

.mr-3 {
  margin-right: 1rem;
}

//Text
.text-wrapper {

  > textarea {

      &.invalid {
          background-color: #fff;
          border: 1px solid #d4cfbc;
          color: #444444;
      }
  }
}

oir-toggle.disabled {
  label.toggle input[type="checkbox"].toggle + span.slider {
    background-color: #bfbbaa;
  }
  label.toggle span.slider:before {
    background-color: lightgray;
  }
}

oir-singleselect .single-select-dropdown .dropdown-toggle.disabled[disabled] {
  background-color: #bfbbaa;
}

.isEditMode {
  opacity: 0.5;
  pointer-events: none;
}

.arrangement-field {
  div[role="textbox"] {
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: #444444;
    padding-bottom: 10px;
  }
}

.bottom-hr {
  border-bottom: 2px solid @primary-50;
}

@-webkit-keyframes top-img{
  from{ -webkit-transform:rotateY(0deg);   }
  to  { -webkit-transform:rotateY(360deg); }
}
@keyframes top-img{
  from {  -moz-transform:rotateY(0deg);
          -ms-transform:rotateY(0deg);
          transform:rotateY(0deg);
       }
  to   {
          -moz-transform:rotateY(360deg);
          -ms-transform:rotateY(360deg);
          transform:rotateY(360deg);
       }
}
.spinner-bg{
  position: absolute;
  top: 0;
  opacity: .75;
  background-color: #000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.spinner-bg.fixed{
  position: fixed;
  left: 0;
  z-index: 9999;
}
.spinner-wrapper{
  position: absolute;
  top: 50%;
  margin-top: -106.5px;
  left: 50%;
  margin-left: -250px;
}
img{
  position: absolute;
  text-align: center;
  vertical-align: middle;
}
#stage{
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  -ms-perspective: 1500px;
  perspective: 1500px;
}
#top-img{
  z-index: 2;

  -webkit-animation-name: top-img;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: .5s;

  animation-name: top-img;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;

  -webkit-transform-style: preserve-3d;
  /*-moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;*/
  transform-style: preserve-3d;
}
#bottom-img{
  z-index: 1;
}
